import React from 'react'
import { graphql } from 'gatsby'
import tw, { styled } from 'twin.macro'
import background from '../images/Hero_Background_Image_02.svg'
import { LocaleContext } from '../components/layout'
import KeyBenefits from '../components/solutions/key-benefits'
import SolutionTestimonial from '../components/solutions/solution-testimonial'
import EverythingStarts from '../components/solutions/everything-starts'
import ForecastSettings from '../components/solutions/forecast-settings'
import PredictYourSales from '../components/solutions/predict-your-sales'
import WatchElementAnimate from '../components/watch-element-animate'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Integrate from '../components/solutions/integrate'
import Implement from '../components/solutions/implement'
import Next from '../components/solutions/next'
import { withPreview } from 'gatsby-source-prismic'
import SEO from '../components/SEO/SEO'
import LocalizedLink from '../components/localized-link'
import {
  LargeSolidTurquoiseButton,
  LargeOutlineTealButton,
} from '../utils/styles'
import Industries from "../components/home/industries";
// styles
const HeroWrapper = styled.div`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .hero-image {
    max-width: 1123px;
    margin: auto;
  }
  button {
    top: 42px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
`
// markup
const SolutionsPage = ({
  data: { prismicSolutions },
  pageContext: { locale },
}) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  return (
    <main>
      <SEO
        title={prismicSolutions.data.meta_title}
        desc={prismicSolutions.data.meta_description}
        locale={locale}
      />
      {prismicSolutions.data.body.map((b, index) => {
        switch (b.slice_type) {
          case 'feature_subpage':
            return (
                <HeroWrapper tw="text-center pt-28 md:pt-20 pb-28 md:pb-20"
                             id={b.primary.anchor.text}>
                <div tw="container">
                  <h1 tw="text-navy max-w-xs md:max-w-xl m-auto mb-8">
                    {b.primary.feature_headline.text}
                  </h1>
                  <span tw="uppercase tracking-widest text-teal">
                    {b.primary.feature_subheadline}
                  </span>
                  {/*<div className="hero-image">*/}
                  {/*  <Img fluid={prismicSolutions.data.hero_image.fluid} />*/}
                  {/*</div>*/}
                  <div tw="relative">
                    <LocalizedLink to="/request-meeting">
                      <LargeSolidTurquoiseButton tw="max-w-xs md:max-w-sm m-auto absolute z-10">
                        {b.primary.feature_button}
                      </LargeSolidTurquoiseButton>
                    </LocalizedLink>
                  </div>
                </div>
              </HeroWrapper>
            )
          case 'features_subpage_intro':
            return (
              <WatchElementAnimate
                  className={`.solutions__forecast__intro__animated__${index}`}
                  animateName={'animate__fadeInUp'}>
                <div className={`solutions__forecast__intro__animated__${index}`}>
                  <ForecastSettings forecast={b.primary}
                                    wide={true}
                  />
                </div>
              </WatchElementAnimate>
            )
          case 'key_benefits':
            return (
              <KeyBenefits benefits={b.primary} items={b.items} i18n={i18n} />
            )
          case 'client_testimonial':
            return (
              <WatchElementAnimate
                className={`.solutions__testimonial__animated__${index}`}
                animateName={'animate__fadeInUp'}>
                <div className={`solutions__testimonial__animated__${index}`}>
                  <SolutionTestimonial testimonial={b.primary} />
                </div>
              </WatchElementAnimate>
            )
          case 'everything_starts':
            return <EverythingStarts start={b.primary} items={b.items} />
          case 'forecast_settings':
            return (
              <WatchElementAnimate
                className={`.solutions__forecast__animated__${index}`}
                animateName={'animate__fadeInUp'}>
                <div className={`solutions__forecast__animated__${index}`}>
                  <ForecastSettings forecast={b.primary} />
                </div>
              </WatchElementAnimate>
            )
          case 'predict_your_sales':
            return (
              <WatchElementAnimate
                className={`.solutions__predict__animated__${index}`}
                animateName={'animate__fadeInUp'}>
                <div className={`solutions__predict__animated__${index}`}>
                  <PredictYourSales
                    predict={b.primary}
                    items={b.items}
                    button={'Learn more now'}
                    id={'predict-sales'}
                    i18n={i18n}
                  />
                </div>
              </WatchElementAnimate>
            )
          case 'use_your_attributes':
            return (
              <WatchElementAnimate
                className={`.solutions__forecast__animated__${index}`}
                animateName={'animate__fadeInUp'}>
                <div className={`solutions__forecast__animated__${index}`}>
                  <ForecastSettings forecast={b.primary} reverse={true} />
                </div>
              </WatchElementAnimate>
            )
          case 'optimize_your_invetory':
            return (
              <WatchElementAnimate
                className={`.solutions__predict__animated__${index}`}
                animateName={'animate__fadeInUp'}>
                <div className={`solutions__predict__animated__${index}`}>
                  <PredictYourSales
                    predict={b.primary}
                    items={b.items}
                    button={'Learn more now'}
                    id={'optimize-replenishment'}
                    i18n={i18n}
                  />
                </div>
              </WatchElementAnimate>
            )
          case 'define_kpi':
            return (
              <WatchElementAnimate
                className={`.solutions__forecast__animated__${index}`}
                animateName={'animate__fadeInUp'}>
                <div className={`solutions__forecast__animated__${index}`}>
                  <ForecastSettings forecast={b.primary} />
                </div>
              </WatchElementAnimate>
            )
          case 'predict_your_finance':
            return (
              <WatchElementAnimate
                className={`.solutions__predict__animated__${index}`}
                animateName={'animate__fadeInUp'}>
                <div className={`solutions__predict__animated__${index}`}>
                  <PredictYourSales
                    predict={b.primary}
                    items={b.items}
                    button={'Learn more now'}
                    id={'plan-finance'}
                    i18n={i18n}
                  />
                </div>
              </WatchElementAnimate>
            )
          case 'how_to_integrate':
            return (
              <WatchElementAnimate
                className={`.solutions__animated__${index}`}
                animateName={'animate__fadeInUp'}>
                <div className={`solutions__animated__${index}`}>
                  <Integrate
                    integrate={b.primary}
                    items={b.items}
                    i18n={i18n}
                  />
                </div>
              </WatchElementAnimate>
            )
          case 'easy_to_implement':
            return (
              <WatchElementAnimate
                className={`.solutions__animated__${index}`}
                animateName={'animate__fadeInUp'}>
                <div className={`solutions__animated__${index}`}>
                  <Implement
                    industries={b.primary}
                    items={b.items}
                    width={'330'}
                    format={'tab'}
                  />
                </div>
              </WatchElementAnimate>
            )
          case 'what_s_next':
            return (
              <WatchElementAnimate
                className={`.solutions__animated__${index}`}
                animateName={'animate__fadeInUp'}>
                <div className={`solutions__animated__${index}`}>
                  <Next next={b.primary} />
                </div>
              </WatchElementAnimate>
            )
          case 'industries':
            return (
                <Industries
                    industries={b.primary}
                    items={b.items}
                    format={'select'}
                    i18n={i18n}
                />
            )
          default:
            return null
        }
      })}
    </main>
  )
}

export default withPreview(SolutionsPage)
export const pageQuery = graphql`
  query SolutionsByUid($uid: String, $locale: String!) {
    prismicSolutions(uid: { eq: $uid }, lang: { eq: $locale }) {
      data {
        meta_title
        meta_description
        body {
          ... on PrismicSolutionsBodyKeyBenefits {
            slice_type
            primary {
              headline {
                text
              }
              subheadline {
                text
              }
            }
            items {
              card_description
              card_headline
              learn_more
              card_icon {
                fluid(maxWidth: 32) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicSolutionsBodyClientTestimonial {
            slice_type
            primary {
              text
              role
              name
              link {
                link_type
                type
                lang
                uid
              }
              button1
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicSolutionsBodyEverythingStarts {
            slice_type
            primary {
              subheadline {
                text
              }
              headline {
                text
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
            items {
              mobile_image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicSolutionsBodyForecastSettings {
            slice_type
            primary {
              headline {
                text
              }
              list {
                html
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              description {
                html
              }
            }
          }
          ... on PrismicSolutionsBodyPredictYourSales {
            slice_type
            primary {
              top_icon {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              subheadline {
                text
              }
              headline {
                text
              }
            }
            items {
              benefits_headline
              benefits_description
            }
          }
          ... on PrismicSolutionsBodyUseYourAttributes {
            slice_type
            primary {
              headline {
                text
              }
              list {
                html
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              description {
                html
              }
            }
          }
          ... on PrismicSolutionsBodyOptimizeYourInvetory {
            slice_type
            primary {
              top_icon {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              subheadline {
                text
              }
              headline {
                text
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
            items {
              benefits_headline
              benefits_description
            }
          }
          ... on PrismicSolutionsBodyDefineKpi {
            slice_type
            primary {
              headline {
                text
              }
              list {
                html
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              description {
                html
              }
            }
          }
          ... on PrismicSolutionsBodyPredictYourFinance {
            slice_type
            primary {
              top_icon {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              subheadline {
                text
              }
              headline {
                text
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
            items {
              benefits_description
              benefits_headline
            }
          }
          ... on PrismicSolutionsBodyHowToIntegrate {
            slice_type
            primary {
              subheadline {
                text
              }
              headline {
                text
              }
              icon_1 {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              icon_2 {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              icon_3 {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              icon_4 {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              description_1
              description_2
              description_3
              description_4
              headline_4 {
                text
              }
              headline_3 {
                text
              }
              headline_2 {
                text
              }
              headline_1 {
                text
              }
            }
            items {
              tab_description
              headline {
                text
              }
              icon {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              tab_name
            }
          }
          ... on PrismicSolutionsBodyEasyToImplement {
            slice_type
            primary {
              subheadline {
                text
              }
              headline {
                text
              }
            }
            items {
              image {
                fluid(maxWidth: 330) {
                  ...GatsbyPrismicImageFluid
                }
              }
              name {
                text
              }
              headline {
                text
              }
              description {
                html
              }
              benefit {
                html
              }
            }
          }
          ... on PrismicSolutionsBodyWhatSNext {
            slice_type
            primary {
              subheadline {
                text
              }
              headline {
                text
              }
              more {
                text
              }
              more_description {
                html
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              link {
                link_type
                type
                lang
                uid
              }
              button1
            }
          }
          ... on PrismicSolutionsBodyFeatureSubpage {
            slice_type
            primary {
              feature_headline {
                text
              }
              feature_subheadline
              feature_button
              anchor {
                text
              }
            }
          }
          ... on PrismicSolutionsBodyFeaturesSubpageIntro {
            slice_type
            primary {
              headline {
                text
              }
              list {
                html
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              description {
                html
              }
              reverse
            }
          }
          ... on PrismicSolutionsBodyIndustries {
            slice_type
            primary {
              headline {
                text
              }
              subheadline {
                text
              }
            }
            items {
              name {
                text
              }
              link {
                url
                slug
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              headline {
                text
              }
              description {
                html
              }
              benefit {
                html
              }
              link {
                url
              }
            }
          }
        }
      }
      uid
    }
  }
`
