import React, { useState } from 'react'
import tw, { styled } from 'twin.macro'
import Img from 'gatsby-image'
import Slider from 'react-slick'
import Select from 'react-select'
import LocalizedLink from '../localized-link'
import { LargeSolidTealButton } from '../../utils/styles'

const IntegrateWrapper = styled.div`
  .tabs {
    border-radius: 36px;
    background: #edf4fb;
    div {
      padding-top: 23px;
      padding-bottom: 23px;
    }
    .active {
      background: #38c3e0;
      border-radius: 36px;
      h4 {
        color: white;
      }
    }
  }
  .tab-info {
    .active {
      background: white;
      box-shadow: 0px 32px 90px rgba(38, 60, 92, 0.155878);
    }
  }
  .integrate-slider {
    margin: 30px -25px 0 -25px;
    padding-bottom: 75px;
  }
  .integrate-dots {
    bottom: 40px;
  }
`
const IntegrateSlider = ({ index, items }) => {
  const settings = {
    dots: true,
    arrows: false,
    dotsClass: 'slick-dots slick-thumb integrate-dots',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: index,
  }
  return (
    <Slider
      {...settings}
      tw="md:hidden mt-16 bg-air"
      className="integrate-slider">
      {items.map((item, key) => {
        return (
          <div key={key} tw="px-10 pt-10 pb-4">
            <div tw="bg-white py-14">
              <span tw="block mb-5 text-teal text-sm">0{key + 1}</span>
              <Img fluid={item.icon.fluid} tw="w-24 m-auto" />
              <h5 tw="mb-5 text-navy">{item.headline.text}</h5>
              <p tw="w-52 m-auto text-ocean">{item.tab_description}</p>
            </div>
          </div>
        )
      })}
    </Slider>
  )
}
const Integrate = ({ integrate, items, i18n }) => {
  const [activeTab, setActiveTab] = useState(0)
  const [option, setOption] = useState({
    value: 0,
    label: items[0].tab_name,
  })
  const handleTabClick = (key) => {
    setActiveTab(key)
  }

  const options = items.map((i, key) => ({ value: key, label: i.tab_name }))

  const handleChange = (selectedOption) => {
    setOption(selectedOption)
  }
  return (
    <IntegrateWrapper
      tw="container pt-20 pb-8 text-center md:pt-28 md:pb-14"
      id="integrate-with-lightspeed">
      <h2 tw="text-navy">{integrate.headline.text}</h2>
      <p tw="text-xl text-ocean pt-5">{integrate.subheadline.text}</p>
      <div tw="py-16">
        <div tw="hidden md:grid grid-cols-4" className="tabs">
          {items.map((item, key) => {
            return (
              <div
                key={key}
                className={activeTab === key ? 'active' : ''}
                onClick={() => handleTabClick(key)}
                tw="cursor-pointer">
                <h4 tw="text-teal font-semibold	">{item.tab_name}</h4>
              </div>
            )
          })}
        </div>
        <div tw="md:hidden text-left">
          <Select
            options={options}
            value={option}
            onChange={handleChange}
            className="q-select"
            classNamePrefix="q"
          />
        </div>
        <div
          tw="hidden md:grid grid-cols-4 mt-16 bg-air p-8 rounded-lg"
          className="tab-info">
          {items.map((item, key) => {
            return (
              <div
                key={key}
                tw="px-10 pt-10 pb-4 rounded-lg"
                className={activeTab === key ? 'active' : ''}>
                <span tw="block mb-5 text-teal text-sm">0{key + 1}</span>
                <Img fluid={item.icon.fluid} tw="w-24 m-auto" />
                <h5 tw="mb-5 text-navy">{item.headline.text}</h5>
                <p tw="w-52 m-auto text-ocean">{item.tab_description}</p>
              </div>
            )
          })}
        </div>
        <IntegrateSlider
          index={option.value}
          items={items}
          key={option.value}
        />
      </div>
      <div tw="grid md:grid-cols-2 md:grid-rows-2 gap-8 md:gap-10">
        <div tw="flex text-left gap-4">
          <div>
            <Img fluid={integrate.icon_1.fluid} tw="w-8" />
          </div>
          <div>
            <h4 tw="text-navy mb-4">{integrate.headline_1.text}</h4>
            <p tw="text-ocean text-lg">{integrate.description_1}</p>
          </div>
        </div>
        <div tw="flex text-left gap-4">
          <div>
            <Img fluid={integrate.icon_2.fluid} tw="w-8" />
          </div>
          <div>
            <h4 tw="text-navy mb-4">{integrate.headline_2.text}</h4>
            <p tw="text-ocean text-lg">{integrate.description_2}</p>
          </div>
        </div>
        <div tw="flex text-left gap-4">
          <div>
            <Img fluid={integrate.icon_3.fluid} tw="w-8" />
          </div>
          <div>
            <h4 tw="text-navy mb-4">{integrate.headline_3.text}</h4>
            <p tw="text-ocean text-lg">{integrate.description_3}</p>
          </div>
        </div>
        <div tw="flex text-left gap-4">
          <div>
            <Img fluid={integrate.icon_4.fluid} tw="w-8" />
          </div>
          <div>
            <h4 tw="text-navy mb-4">{integrate.headline_4.text}</h4>
            <p tw="text-ocean text-lg">{integrate.description_4}</p>
          </div>
        </div>
      </div>

      <LocalizedLink to="/request-meeting">
        <LargeSolidTealButton tw="mt-16 md:w-96">
          {i18n.buttons.meeting}
        </LargeSolidTealButton>
      </LocalizedLink>
    </IntegrateWrapper>
  )
}
export default Integrate
