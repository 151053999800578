import React, { useState } from 'react'
import tw, { styled } from 'twin.macro'
import Slider from 'react-slick'
import Select from 'react-select'
import Img from 'gatsby-image'
import Check from '../../images/Check.svg'
import LocalizedLink from '../localized-link'
import { SmallSolidTealButton } from '../../utils/styles'
const ImplementWrapper = styled.div`
  .slick-slider {
    padding-top: 150px;
    .slick-dots {
      top: 25px;
      bottom: auto;
      li {
        width: 280px;
        height: 72px;
        margin: 0;
        background: #edf4fb;
        p {
          padding-top: 20px;
          padding-bottom: 20px;
          color: #007e9f;
        }
      }
      li:first-of-type {
        border-radius: 36px 0 0 36px;
      }
      li:last-of-type {
        border-radius: 0 36px 36px 0;
      }
      .slick-active {
        p {
          background: #38c3e0;
          border-radius: 36px;
          color: #fff;
        }
      }
    }
  }
  .industry-item {
    display: flex !important;
    .item-info {
      max-width: 500px;
      li {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }
      li::before {
        content: '';
        display: block;
        margin-right: 12px;
        background-size: cover;
        background-image: url(${Check});
        width: 16px;
        height: 16px;
        padding: 0 9px;
      }
    }
  }

  @media (max-width: 640px) {
    .slick-slider {
      padding-top: 150px;
      .slick-dots {
        top: 25px;
        bottom: auto;
        li {
          width: 135px;
          height: 60px;
          p {
            padding-top: 14px;
            padding-bottom: 14px;
            font-size: 18px;
          }
        }
        .slick-active {
          p {
            background: #007e9f;
          }
        }
      }
    }
    .industry-item {
      display: block !important;
    }
  }
`
const Implement = ({ industries, items, width, format, i18n }) => {
  const [industry, setIndustry] = useState({
    value: 0,
    label: items[0].name.text,
  })
  const settings = {
    customPaging: function (i) {
      return (
        <a>
          <p tw="text-xl font-semibold">{items[i].name.text}</p>
        </a>
      )
    },
    dots: true,
    arrows: false,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const options = items.map((i, key) => ({ value: key, label: i.name.text }))

  const handleChange = (selectedOption) => {
    setIndustry(selectedOption)
  }
  const styles = {
    tab: tw``,
    select: tw`hidden md:block`,
  }
  return (
    <ImplementWrapper tw="container py-8 lg:p-14 relative z-10">
      <div tw="text-center">
        <h2 tw="text-navy md:w-4/6 m-auto">{industries.headline.text}</h2>
        <p tw="text-xl md:w-3/6 m-auto text-ocean py-4">
          {industries.subheadline.text}
        </p>
      </div>
      {format === 'select' ? (
        <div tw="md:hidden">
          <div tw="py-8">
            <Select
              options={options}
              value={industry}
              onChange={handleChange}
              className="q-select"
              classNamePrefix="q"
            />
          </div>
          <div tw="md:flex" className="industry-item">
            <div tw="md:flex-none flex items-center" className="item-info">
              <div>
                <h3 tw="text-navy font-black">
                  {items[industry.value].headline.text}
                </h3>
                <div tw="md:flex-grow mt-8">
                  <Img fluid={items[industry.value].image.fluid} />
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: items[industry.value].description.html,
                  }}
                  tw="text-ocean mt-5 text-lg"></div>

                <div
                  dangerouslySetInnerHTML={{
                    __html: items[industry.value].benefit.html,
                  }}
                  tw="text-navy mt-5 leading-10 text-lg"></div>
              </div>
            </div>
          </div>
          <LocalizedLink to="/request-meeting">
            <SmallSolidTealButton tw="w-full mt-8">
              {i18n.buttons.meeting}
            </SmallSolidTealButton>
          </LocalizedLink>
        </div>
      ) : (
        false
      )}
      <Slider {...settings} css={styles[format]}>
        {items.map((item, key) => {
          return (
            <div
              tw="md:flex justify-center gap-24"
              className="industry-item"
              key={key}>
              <div tw="flex items-center" className="item-info">
                <div>
                  <div
                    tw="md:hidden md:flex-grow"
                    style={{
                      maxWidth:
                        typeof width != 'undefined' ? width + 'px' : false,
                      margin: 'auto',
                    }}>
                    <Img fluid={item.image.fluid} />
                  </div>
                  <h3 tw="text-navy font-black">{item.headline.text}</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.description.html,
                    }}
                    tw="text-ocean mt-5 text-lg"></div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.benefit.html,
                    }}
                    tw="text-navy mt-5 leading-10 text-lg"></div>
                </div>
              </div>
              <div
                tw="hidden md:block md:flex-grow"
                style={{
                  maxWidth: typeof width != 'undefined' ? width + 'px' : false,
                }}>
                <Img fluid={item.image.fluid} />
              </div>
            </div>
          )
        })}
      </Slider>
    </ImplementWrapper>
  )
}
export default Implement
