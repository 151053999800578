import React from 'react'
import tw, { styled } from 'twin.macro'
import Img from 'gatsby-image'
import WatchElementAnimate from '../../components/watch-element-animate'
import Slider from 'react-slick'

const EverythingStartsWrapper = styled.div`
  .starts-slider {
    margin: 30px -25px 0 -25px;
    padding-bottom: 75px;
  }
  .starts-dots {
    bottom: 40px;
  }
  .slider-image {
    width: 110%;
  }
`

const EverythingStarts = ({ start, items }) => {
  const settings = {
    dots: true,
    arrows: false,
    dotsClass: 'slick-dots slick-thumb starts-dots',
    infinite: false,
    speed: 500,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
  }
  return (
    <WatchElementAnimate
      className={'.solutions__everything__animated'}
      animateName={'animate__fadeInUp'}>
      <EverythingStartsWrapper
        tw="container pt-20 pb-8 text-center md:pt-28 md:pb-14"
        className="solutions__everything__animated">
        <h2 tw="text-navy mb-4 md:mb-5">{start.headline.text}</h2>
        <p tw="text-xl text-ocean max-w-xl m-auto">{start.subheadline.text}</p>
        <div tw="hidden md:block pt-12 max-w-4xl m-auto">
          <Img fluid={start.image.fluid}></Img>
        </div>
        <Slider
          {...settings}
          tw="md:hidden pt-12 bg-air"
          className="starts-slider">
          {items.map((item, key) => {
            return (
              <div key={key}>
                <Img fluid={item.mobile_image.fluid} class="slider-image" />
              </div>
            )
          })}
        </Slider>
      </EverythingStartsWrapper>
    </WatchElementAnimate>
  )
}
export default EverythingStarts
