import React from 'react'
import tw, { styled } from 'twin.macro'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { LargeSolidTurquoiseButton } from '../../utils/styles'
import linkResolver from '../../gatsby/linkResolver'

const NextWrapper = styled.div`
  @media (min-width: 769px) {
    .next-image {
      width: 500px;
    }
  }
`

const Next = ({ next }) => {
  const link = linkResolver(next.link)

  return (
    <NextWrapper tw="container pt-5 md:pt-20 pb-20 text-center md:pt-8 md:pb-28">
      <h2 tw="text-navy">{next.headline.text}</h2>
      <p tw="text-xl text-ocean max-w-xl m-auto mt-5">
        {next.subheadline.text}
      </p>
      <div tw="md:flex mt-8 md:mt-10 rounded-lg">
        <div
          tw="md:flex-none rounded-t-lg md:rounded-l-lg"
          className="next-image">
          <Img fluid={next.image.fluid} tw="rounded-t-lg md:rounded-l-lg"></Img>
        </div>
        <div tw="bg-ocean py-14 px-16 md:flex-grow md:text-left rounded-b-lg md:rounded-b-none md:rounded-r-lg">
          <h3 tw="text-white">{next.more.text}</h3>
          <div
            dangerouslySetInnerHTML={{
              __html: next.more_description.html,
            }}
            tw="text-white mt-4 text-lg"></div>
          <Link to={link}>
            <LargeSolidTurquoiseButton tw="max-w-xs mt-8 mb-0">
              {next.button1}
            </LargeSolidTurquoiseButton>
          </Link>
        </div>
      </div>
    </NextWrapper>
  )
}
export default Next
