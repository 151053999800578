import React from 'react'
import tw, { styled } from 'twin.macro'
import Img from 'gatsby-image'
import Check from '../../images/Check.svg'
const ForecastSettingsWrapper = styled.div`
  p {
    margin-bottom: 1rem;
  }

  .list-html {
    text-align: left;
    li {
      margin-bottom: 10px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-box;
      align-items: center;
      gap: 0.25rem;
    }
    li::before {
      content: '';
      display: block;
      margin-right: 12px;
      background-size: cover;
      background-image: url(${Check});
      width: 16px;
      height: 16px;
      padding: 0 9px;
    }
  }
`

const ForecastSettings = ({ forecast, reverse, wide }) => {
  // reverse: image on left side, text on right side
  const flow = reverse || forecast.reverse
        ? tw`md:flex flex-row-reverse`
        : tw`md:flex flex-row`;
  const widthStyle = wide
      ? tw`md:flex-none max-w-3xl flex items-center`
      : tw`md:flex-none max-w-lg flex items-center`;
  return (
    <ForecastSettingsWrapper tw="container pt-20 pb-8 text-center md:text-left md:pt-28 md:pb-14">
      <div css={flow}>
        <div css={widthStyle} >
          <div>
            <h3 tw="text-navy font-black">{forecast.headline.text}</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: forecast.description.html,
              }}
              tw="text-ocean mt-5 text-lg text-left"></div>
            <div
              dangerouslySetInnerHTML={{
                __html: forecast.list.html,
              }}
              tw="text-navy mt-5 leading-10 text-lg"
              className="list-html"></div>
          </div>
        </div>
        <div tw="md:flex-grow pt-8 md:pt-0">
          <Img fluid={forecast.image.fluid} />
        </div>
      </div>
    </ForecastSettingsWrapper>
  )
}
export default ForecastSettings
